@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/56f6f568d0e1c7710e91082da8c0b56a4cd8ba9e.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/8c880f2ea4333169fd6fa835dde285a3b1bd311f.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/572b78c8d54bb15b80f1d73a8dff557f1d2d7915.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/c236bcc385d148366680da9d4f96d4af4e0a43a6.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/357a662c1dc2171f41c7c702b5b98ff8b962abe5.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/d8fb9691aceaca6e0aea352c144c3cf2f86f4493.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/05519436d275314db9a32ae811e21a6f2b461c3e.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/d1aabb5cf4564bf35f2161a0db033afea60eed33.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/081e410b3b2dda4ef5e2c0fe438386418df776b9.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/aa2601c26593a7c0d09701641da38bfb10d80c64.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/ff60f75cf80572d9fe1ce7b7ffb7e0bd7ea34c9e.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/ccc16f7d59f59a31de16813eb103b1cae98e8726.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/e304abec844b086b8123bf162c8643bdc4ab2bf9.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/51a9fa5dd612dbec6409125c464cb0b253d462ac.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/209730ad01b288a44b2a375d0bc9e44148c7a081.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/46244970d46da983b62f8188a14352ecfffaee03.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/58f921a5f03053c30878608a95811a5078804231.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/9f3655a86e3b921459584c153f44118b7d5269a1.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/44e83d2a89a82a4f7861a2d49480f81ff2c4ac80.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/4e8f7e5954b094e591bd89a40c6d2fe493189f81.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/aa0b3c56c1fa6ca75a695b06c79f30f2c2b51756.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/93f70258de8df850d926b4004212c72a1fed25a4.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/b7a8eed9aa132fca8be5461e2d3494db007a0b07.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/66c0f15dd6cd3db461f7d2b8b001ecefaff2b7f4.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/8ac8e6c02814b5657fcca120e60a0af3f2ff7da9.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/650b195b2c47bdaada34c7665bda1cb563e23dc8.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/7885da5617e71507638730804a76077e4886a79f.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('https://minicdn.samoletgroup.ru/file/297aa74f3f2c76c7463ab2e50cbcec1a5211a3df.woff2') format('woff2'),
    url('https://minicdn.samoletgroup.ru/file/c56abb945da0d78676d8d3849bad48793ffde91f.woff') format('woff'),
    url('https://minicdn.samoletgroup.ru/file/3ac15ef9e8f53ed0362b3d3cd8e8f2620ad1c592.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}